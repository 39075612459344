'use client';

import { Center } from '@chakra-ui/react';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

// components
import Loading from '../loading';

interface Props {
  path: string;
}

const Redirect = ({ path }: Props) => {
  const router = useRouter();

  useEffect(() => {
    router.push(path);
  }, [path]);

  return <Loading />;
};

export default Redirect;
