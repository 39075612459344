'use client';

import { Center } from '@chakra-ui/react';
import { SignIn } from '@clerk/nextjs';
import { useSearchParams } from 'next/navigation';

// components
import Redirect from '../../../app/redirect/Redirect';

const SignInPage = () => {
  const searchParams = useSearchParams();

  const redirectUrl = searchParams.get('redirect_url');

  if (redirectUrl) {
    return <Redirect path="/sign-in" />;
  }

  return (
    <Center
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <SignIn
        path="/sign-in"
        routing="path"
        signUpUrl="/sign-up"
        redirectUrl="/redirect"
        appearance={{
          variables: { colorPrimary: '#1490B7' },
        }}
      />
    </Center>
  );
};

export default SignInPage;
